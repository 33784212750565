<template>
  <el-main class="rh-medform">
    <h1>Tuteur</h1>
    <form-tutoratedit :ismedtut="true" :routes="routes" />
  </el-main>
</template>
<script>
import FormTutoratedit from "@/components/rh/formulaires/FormTutoratedit";

export default {
  components: { "form-tutoratedit": FormTutoratedit },

  computed: {
    routes() {
      return {
        tolist: {
          name: "med_tuteurs",
          params: { idmed: this.$route.params.idmed },
        },
        evaform: {
          edit: { route: "med_tut_evaluations_edit", params: { ideval: "id" } },
          create: { route: "med_tut_evaluations_create" },
        },
      };
    },
  },
};
</script>
